import React, { useContext } from 'react'

import { Loader } from '@cuidardigital/commons/components/Loader'
import ShowModal from '@cuidardigital/commons/components/Modal'
import useState from '@cuidardigital/commons/hooks/useState'

import {
	postGenerateCertificateTicket,
	putUpdateDoctorOptins
} from '@cuidardigital/commons/services/certification.valid.services'
import { getDoctorInfo } from '@cuidardigital/commons/services/doctor.services'
import { graphql, useStaticQuery } from 'gatsby'
import {
	ActionsContainer,
	ButtonLink,
	Checkbox,
	CheckboxContainer,
	CheckboxText,
	Container,
	ContentContainer,
	ContentDescription,
	ContentTitle,
	Logo,
	LogoContainer,
	ReturnButton,
	Span,
	SubmitButtonRed
} from './styles'
import iconX from '../../../assets/icons/svg/close.svg'
import DigitalCertificateRejectionModal from './DigitalCertificateReject'
import DigitalCertificateAcceptanceModal from './DigitalCertificateAcceptance'
import logo from '../../../assets/img/logo.png'
import { store } from '../../../stores/app'
import { Icon } from '../ModalDigitalSign/styles'

interface IDigitalCertificateModal {
	handleModal: (param?: boolean) => void
	handleAcceptance: (param: boolean) => void
	digitalCertificateAcceptance: boolean | null
}

const initialState = {
	checked: false
}

const DigitalCertificateModal: React.FC<IDigitalCertificateModal> = ({
	handleAcceptance,
	handleModal,
	digitalCertificateAcceptance
}) => {
	const { state: stateStore } = useContext(store)
	const { cpf } = stateStore.session
	const [state, handleState] = useState(initialState)

	// busca e extração dos dados do cosmic
	const data = useStaticQuery(pageQuery)
	const {
		title,
		paragraph,
		buttonAccept,
		buttonDecline,
		notPerturb
	} = data.saudeDigital.metadata.digitalCertification.ad

	const handleCheckbox = () => {
		handleState({ checked: !state.checked })
	}

	const handleLoading = (value: boolean) => handleState({ loading: value })
	const handleNotPerturb = async () => {
		const { doctorCurrent } = stateStore
		if (doctorCurrent.name) {
			putUpdateDoctorOptins(doctorCurrent.optins, 'NAOVERMAISADVALID')
		} else {
			const response = await getDoctorInfo(cpf)

			if (response && response.status === 200) {
				const { optins } = response.data
				putUpdateDoctorOptins(optins, 'NAOVERMAISADVALID')
			}
		}
		handleModal(false)
	}

	const handleCloseIcon = () => handleModal(false)

	const handleClose = () => {
		if (state.checked) handleAcceptance(false)
		else handleModal(false)
	}

	const handleContinueToTicket = async () => {
		const openNewTab = (url: string) => {
			const win = window.open(url, '_blank')
			if (win) win.focus()
		}

		const getTicket = async ({ phone, email, name }: any) => {
			let cellPhone = phone.replace(/\D/g, '')
			cellPhone = `(${cellPhone.slice(0, 2)})${cellPhone.slice(2, 7)}-${cellPhone.slice(7)}`

			const data = {
				cellPhone,
				email,
				name: name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
				cpfCnpj: cpf
			}
			const acceptanceResponse = await postGenerateCertificateTicket(data)
			if (acceptanceResponse && acceptanceResponse.status === 200) {
				const { url } = acceptanceResponse.data
				handleModal()
				openNewTab(url)
			}
		}

		handleLoading(true)
		const { doctorCurrent } = stateStore
		if (doctorCurrent.name) {
			getTicket(doctorCurrent)
		} else {
			const response = await getDoctorInfo(cpf)

			if (response && response.status === 200) {
				getTicket(response.data)
			}
			handleLoading(false)
		}
	}

	if (state.loading) return <Loader />
	return (
		<ShowModal p='0' maxWidth='460px' onClose={handleCloseIcon}>
			<Icon src={iconX} onClick={handleCloseIcon} />
			<Container>
				<LogoContainer>
					<Logo src={logo} />
				</LogoContainer>
				{digitalCertificateAcceptance === null ? (
					<>
						<ContentContainer>
							<Span bold fontSize='20px/20px' wrap>
								{title}
							</Span>
							<Span wrap fontSize='16px/20px' padding='5px 0 0 0' mb='5vh'>
								{paragraph}
							</Span>
						</ContentContainer>
						<ActionsContainer>
							{/*  tslint:disable-next-line: jsx-no-lambda */}
							<SubmitButtonRed id='button-ad-accept' onClick={() => handleAcceptance(true)}>
								{buttonAccept}
							</SubmitButtonRed>
							<ReturnButton id='button-ad-decline' onClick={handleClose}>
								{buttonDecline}
							</ReturnButton>
							<CheckboxContainer>
								<Checkbox
									id='checkbox-not-perturb'
									type='checkbox'
									checked={state.checked}
									onChange={handleCheckbox}
								/>
								<Span wrap fontSize='12px/12px' padding='0 0 0 3px' mb='2vh' display='inline'>
									{notPerturb}
								</Span>
							</CheckboxContainer>
						</ActionsContainer>
					</>
				) : digitalCertificateAcceptance === true ? (
					<DigitalCertificateAcceptanceModal
						handleModal={handleClose}
						handleLoading={handleLoading}
						onContinue={handleContinueToTicket}
					/>
				) : (
					<DigitalCertificateRejectionModal handleModal={handleClose} onSubmit={handleNotPerturb} />
				)}
			</Container>
		</ShowModal>
	)
}

const pageQuery = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				digitalCertification: certificacao_digital {
					ad: oferta {
						title: titulo
						paragraph: paragrafo
						buttonAccept: botao_aceite
						buttonDecline: botao_recusa
						notPerturb: nao_ver_mais_oferta
					}
				}
			}
		}
	}
`

export default DigitalCertificateModal
