import React from 'react'

import {
	ActionsContainer,
	Button,
	ButtonContainer,
	ButtonText,
	Container,
	ContentContainer,
	ContentDescription,
	ContentList,
	ContentTitle,
	Img,
	Link
} from './styles'

import { graphql, useStaticQuery } from 'gatsby'
import { ButtonLink, ReturnButton, SubmitButtonRed } from '../styles'
interface IProps {
	handleModal: () => void
	onContinue: () => void
}

const DigitalCertificateAcceptance: React.FC<IProps> = ({ handleModal, onContinue }) => {
	// busca e extração dos dados do cosmic
	const data = useStaticQuery(pageQuery)
	const {
		title,
		paragraph,
		buttonAccept,
		buttonDecline,
		instructions
	} = data.saudeDigital.metadata.digitalCertification.ad

	return (
		<Container>
			<ContentContainer>
				<ContentTitle>{title}</ContentTitle>
				<ContentDescription>{paragraph}</ContentDescription>
				<ContentList>
					{instructions.map(instruction => (
						<>
							<Link href={instruction.link} target='_blank'>
								<Img src={instruction.icon} />
								<span>{instruction.title}</span>
							</Link>
							<br />
						</>
					))}
				</ContentList>
			</ContentContainer>
			<ActionsContainer>
				<SubmitButtonRed id='button-ticket-submit' onClick={onContinue}>
					{buttonAccept}
				</SubmitButtonRed>
				<ButtonLink id='button-ticket-decline' onClick={handleModal}>
					{buttonDecline}
				</ButtonLink>
			</ActionsContainer>
		</Container>
	)
}
const pageQuery = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				digitalCertification: certificacao_digital {
					ad: oferta {
						title: titulo_instrucao
						paragraph: paragrafo_instrucao
						buttonAccept: botao_aceite_instrucoes
						buttonDecline: botao_recusa_instrucoes
						instructions: tutoriais {
							title: titulo
							link: link_do_material
							icon: icone
						}
					}
				}
			}
		}
	}
`
export default DigitalCertificateAcceptance
