// @ts-ignore
import React from 'react'
import styled from 'styled-components'

interface IFormGroup {
	marginRight?: string
	selectWidth?: string
}

const FormGroup = styled.div`
	text-align: left;
	margin-left: auto;
	margin-right: ${({ marginRight }: IFormGroup) => marginRight || 'auto'};
	position: relative;
	width: ${({ selectWidth }) => selectWidth || '100%'};
`
interface ILabel {
	lineHeight?: string
	color?: string
	fontSize?: string
}

export const Label = styled.label`
	font: 14px/32px CentraleSansRegular;
	white-space: pre;
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	line-height: ${({ lineHeight }: ILabel) => lineHeight || '60px'};
	background: transparent;
	margin: 0px auto;
	cursor: text;
	transition: all 0.15s ease-in-out;
	height: 21px;
	color: ${({ color }) => color || '#a2a2a2'};
	${({ fontSize }: IInput) => (fontSize ? `font-size: ${fontSize}` : '')};
	font-style: normal;
	font-stretch: normal;
	letter-spacing: normal;
	text-align: left;

	@media (max-width: 1024px) {
		font-size: 12px;
	}

	@media (max-width: 768px) {
		font-size: 16px;
	}
`

interface IInput {
	fontSize?: string
	color?: string
	borderBottom?: string
	inSearch?: boolean
	padding?: string
	error?: string
	paddingInlineEnd?: string
	iconLeft?: boolean
	marginTop?: string
	background?: string
}

const Input = styled.input<IInput>`
font: ${({ bold, fontSize }) =>
	`${bold ? 'Bold' : ''} ${fontSize || '16px/24px'} CentraleSans${bold ? '-Bold' : 'Regular'}`};

border-radius: 4px;
	color: ${({ color }) => color};
	height: 24px;
	border: none;
	border-bottom: ${({ borderBottom, error }) => (error && '1px solid #ff0000') || borderBottom};;
	background-color: ${props => (props.background ? props.background : 'transparent')};
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1;
	letter-spacing: normal;
	text-align: left;
	position: relative;
	display: block;
	width: 100%;
	margin: ${({ iconLeft }) => (iconLeft ? '21px auto auto -21px' : '21px auto auto auto')};
	background: ${({ inSearch }) => (inSearch ? '' : `linear-gradient(to top, red 50%, red 50%)`)};
	background-position: ${({ inSearch }) => (inSearch ? '' : 'left bottom')};
	background-size: ${({ inSearch }) => (inSearch ? '' : '0 1px')};
	background-repeat: ${({ inSearch }) => (inSearch ? '' : 'no-repeat')};
	padding: ${({ padding }) => padding || '0'};
	transition: all 0.2s ease-in-out;

	padding-inline-end: ${({ paddingInlineEnd }) => paddingInlineEnd};
	padding-inline-start: ${({ iconLeft }) => (iconLeft ? '15px' : '')};

	&:focus {
		background-position: left bottom;
		background-size: 100% 1px;
	}

	/* &:not([value=''])+${Label},  */
	&:required:valid+${Label},
	&:focus+${Label} {
		margin-top: ${({ marginTop }) => marginTop};
		height: 20px;
		line-height: 10px;
		font-size: 12px;
	}

	&:focus &::-webkit-input-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus &:-moz-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus &::-moz-placeholder {
		color: transparent;
		font-size: 0;
	}

	&:focus &:-ms-input-placeholder {
		color: transparent;
		font-size: 0;
	}

	outline: none;
	width: 100%;

	@media (max-width: 767.98px) {
		background:  ${({ background }) => background || 'transparent'};
		font-size: 14px;
	}

	@media (max-width: 375px) {
		// padding: 0;
	}
`

/**
 * Componente de busca
 * @param {(event)=>{}} onSearch - callback para executar ao digitar
 * @param {()=>{}} onKeyPress - callback para executar ao digitar Insert
 * @param {string | React.ReactNode | React.ReactElement} iconLeft - item a ser exibido à esqueda do input
 * @param {any} ...props - quaisquer outras props seram colocadas para o elemento de input
 */
const Required = styled.span`
	color: red;
`

interface PropsInput {
	label?: string
	id?: string
	name?: string
	lineHeight?: string
	fontSize?: string
	color?: string
	required?: boolean
	inputFontSize?: string
	inputColor?: string
	inputPadding?: string
	inputBorderBottom?: string
	inputMarginTop?: string
	inputBackground?: string
	inputType?: string
	props?: any
	iconLeft?: boolean
	paddingInlineEnd?: string
	onKeyPress?: (e: any) => any
}
export default ({
	label,
	id,
	name,
	lineHeight,
	fontSize,
	color,
	required,
	inputFontSize,
	inputColor,
	inputPadding,
	inputBorderBottom,
	inputMarginTop,
	inputBackground,
	backgroundColor,
	inputType,
	...props
}: any) => (
	<FormGroup>
		<Input
			required
			type={inputType}
			id={id}
			className='input-float'
			name={name}
			fontSize={inputFontSize}
			color={inputColor}
			padding={inputPadding}
			borderBottom={inputBorderBottom}
			marginTop={inputMarginTop}
			background={inputBackground}
			backgroundColor={backgroundColor}
			{...props}
		/>
		{label && (
			<Label
				htmlFor={id || name}
				className='label-float'
				lineHeight={lineHeight}
				fontSize={fontSize}
				color={color}
			>
				{label}
				{required === true ? <Required>*</Required> : null}
			</Label>
		)}
	</FormGroup>
)
