import styled from 'styled-components'
import {
	Button as SuperSubmitRedButton,
	ButtonLink as SuperButtonLink,
	ButtonReturn as SuperButtonReturn,
	Span as SuperSpan
} from '../../superSetCommons'
interface IButton {
	primary?: boolean
	secondary?: boolean
}

export const Logo = styled.img`
	display: block;
	width: 100%;
	max-width: 420px;
	margin: auto;
`

export const LogoContainer = styled.div``

export const Container = styled.div`
	// display: flex;
	// flex-direction: column;
	padding: 2vh 4vw;
`

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px 0;
`

export const ContentTitle = styled.span`
	color: #333333;
	font-family: 'CentraleSans-Bold';
	text-align: center;
	font-size: 20px;
`

export const ContentDescription = styled.span`
	color: #333333;
	text-align: justify;
	font-family: 'CentraleSansRegular';
	// padding: 15px 35px;
	font-size: 14px;
`

export const ActionsContainer = styled.div``

export const ButtonContainer = styled.div`
	padding: 0px 25px;
`

export const Button = styled.div<IButton>`
	background-color: ${props => (props.primary ? '#e50019' : '#748A96')};
	padding: 10px;
	margin: 10px;
	border-radius: 3px;
	align-self: center;
`

export const ButtonText = styled.span`
	color: #fff;
	font-family: 'CentraleSans-Bold';
	font-size: 14px;
`

export const CheckboxContainer = styled.div`
	align-items: center;
	padding-top: 10px;
`

export const Checkbox = styled.input``

export const CheckboxText = styled.span`
	font-family: 'CentraleSansRegular';
	font-size: 14px;
	margin-left: 8px;
`

export const ButtonLink = styled(SuperButtonLink)`
	color: ${props => props.theme.colors.font_secundary};
`

export const SubmitButtonRed = styled(SuperSubmitRedButton)`
	margin: ${props => props.margin || 'auto 0 0 0'};
`
export const ReturnButton = styled(SuperButtonReturn)`
	width: 100%;
	margin: 10px 0 0 0;
`

export const Span = styled(SuperSpan)`
	display: ${props => props.display || (props.flex ? 'flex' : 'block')};
	// margin: 0px;
	padding: ${props => props.padding || '20px 0 0 0'};
	line-height: ${props => props.lineHeight || '1.5'};
	text-align: left;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
	max-width: 90%;

	strong {
		font-family: CentraleSans-Bold;
	}
`
