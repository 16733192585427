import React from 'react'
import styled from 'styled-components'
import { IconClose, IconCloseGrey, IconSearch } from '../../assets/icons'
import Input from './input'

const CompContainer = styled.div`
	width: ${({ width }: { width?: string }) => width};
	@media (max-width: 991.98px) {
		width: 100%;
	}
`

const SidebarItem = styled.div`
	// padding: 0 0 20px;
`

interface ISearchSpan {
	borderSearch?: boolean
	backgroundSearch?: boolean
	paddingSearch?: boolean
}
const SearchSpan = styled.span`
	// border: 1px solid #999999;
	// border-radius: 5px;
	padding: 5px 10px;
	// width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	background: linear-gradient(to bottom, transparent 35%, #f7f7f7 0%);
	@media (max-width: 767.98px) {
		border: ${({ borderSearch }: ISearchSpan) => (borderSearch ? 'solid 1px #e5e5e5' : 'none')};
		border-radius: unset;
		padding: ${({ paddingSearch }: ISearchSpan) => (paddingSearch ? 'none' : '10px')};
		border-radius: 5px;
	}
`

const SearchButton = styled.button`
	background: none;
	border: none;
	padding: 0;
	margin-top: auto;
	z-index: 0;
	cursor: pointer;
`
// const IoIosSearchIcon = styled(SearchIcon)`
// 	color: ${({ colorIcon, theme }) => (colorIcon ? '#8e8e93' : theme.colors.primary)};
// 	width: 20px;
// 	height: 20px;
// `

export interface PropsSearch {
	fontSize?: string
	onSearch?: (e: any) => any
	onKeyPress?: (e: any) => any
	handleBlur?: (e: any) => any
	onChange?: (e: any) => any
	iconLeft?: any
	background?: string
	backgroundSearch?: boolean
	borderSearch?: boolean
	paddingSearch?: boolean
	idButton?: string
	paddingInlineEnd?: string
	inSearch?: boolean
	width?: string
	label?: string
	search?: boolean
	value?: string
	exit?: boolean
	inputBackground?: string
	inputPadding?: string
	id?: string
}
/**
 * Componente de busca
 * @param {function} onSearch - callback para executar ao digitar
 * @param {function} exit - boolean to indicate that an user can delete an input content
 * @param {function} onKeyPress - callback para executar ao digitar Insert
 * @param {function} handleBlur - callback para executar na mudança de foco
 * @param {icon} iconLeft - item a ser exibido à esqueda do input
 * @param {string} idButton - id para a lupa
 * @param {*} ...props - outras props html para o input
 */
export default ({
	exit,
	onSearch,
	onKeyPress,
	handleBlur,
	iconLeft: IconLeft,
	backgroundSearch,
	borderSearch,
	paddingSearch,
	idButton,
	width,
	search,
	value,
	onChange,
	background,
	inputPadding,
	inputBackground,
	...props
}: PropsSearch) => {
	const handlePressEnter = (e: any) => {
		if (e.key === 'Enter' && onKeyPress) onKeyPress(e)
	}
	return (
		<>
			<CompContainer width={width}>
				<SidebarItem>
					<SearchSpan backgroundSearch={backgroundSearch} borderSearch={borderSearch} paddingSearch={paddingSearch}>
						{IconLeft && <IconLeft />}
						<Input
							value={value}
							inSearch={true}
							onChange={onChange}
							paddingInlineEnd=''
							iconLeft={!!IconLeft}
							inputBorderBottom=''
							onBlur={handleBlur}
							inputPadding={inputPadding}
							onKeyPress={handlePressEnter}
							inputBackground={inputBackground}
							{...props}
						/>
						{search && (
							<SearchButton id={idButton} onClick={onSearch}>
								{<IconSearch />}
							</SearchButton>
						)}
					</SearchSpan>
				</SidebarItem>
			</CompContainer>
		</>
	)
}
