import React from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { SubmitButtonRed } from '../styles'
import { ActionsContainer, Container, ContentContainer, ContentDescription, ContentTitle } from './styles'

interface IProps {
	handleModal: () => void
	onSubmit: () => void
}

const DigitalCertificateRejection: React.FC<IProps> = ({ handleModal, onSubmit }) => {
	// busca e extração dos dados do cosmic
	const data = useStaticQuery(pageQuery)
	const { title, paragraph, button } = data.saudeDigital.metadata.digitalCertification.ad

	return (
		<Container>
			<ContentContainer>
				<ContentTitle>{title}</ContentTitle>
				<ContentDescription>{paragraph}</ContentDescription>
				{/* <ContentDescription>- WhatsApp: 11 97652-9631</ContentDescription>
				<ContentDescription>- Email: cuidardigital@grupofleury.com.br</ContentDescription> */}
			</ContentContainer>
			<ActionsContainer>
				<SubmitButtonRed id='button-not-perturb' onClick={onSubmit}>
					{button}
				</SubmitButtonRed>
			</ActionsContainer>
		</Container>
	)
}
const pageQuery = graphql`
	query {
		saudeDigital: cosmicjsCuidarDigitalMarcas(slug: { eq: "bradesco" }) {
			metadata {
				digitalCertification: certificacao_digital {
					ad: oferta {
						title: titulo_recusa_permanente
						paragraph: paragrafo_recusa_permanente
						button: botao_recusa_permanente
					}
				}
			}
		}
	}
`
export default DigitalCertificateRejection
