import styled from 'styled-components'

interface IButton {
	primary?: boolean
	secondary?: boolean
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 15px;
`

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px 0;
`

export const ContentTitle = styled.span`
	color: #333333;
	font-family: 'CentraleSans-Bold';
	font-size: 20px;
	margin-bottom: 15px;
	/* padding: 15px 35px; */
	align-self: flex-start;
`

export const ContentDescription = styled.span`
	color: #333333;
	text-align: justify;
	font-family: 'CentraleSansRegular';
	font-size: 14px;
`

export const ActionsContainer = styled.div``

export const ButtonContainer = styled.div`
	padding: 0px 25px;
`

export const Button = styled.div<IButton>`
	background-color: ${props => (props.primary ? '#e50019' : '#748A96')};
	padding: 10px;
	margin: 10px;
	border-radius: 3px;
	align-self: center;
`

export const ButtonText = styled.span`
	color: #fff;
	font-family: 'CentraleSans-Bold';
	font-size: 14px;
`

export const CheckboxContainer = styled.div`
	align-items: center;
	padding-top: 10px;
`

export const ContentList = styled.div`
	margin: 15px 0;
	color: ${props => props.theme.colors.font_dark};
	text-align: justify;
	font-family: 'CentraleSansRegular';
	font-size: 14px;
`

export const Img = styled.img`
	width: 3vw;
	height: 3vh;

	path: {
		fill: ${props => props.theme.colors.primary};
	}
`

export const Link = styled.a`
	display: flex;
	align-items: center;
	color: ${props => props.theme.colors.primary};
`
