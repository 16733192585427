import api from './api'
import { endpointsCreator } from './endpoints'

interface IGetAppointments {
	page: number
	limit: number
	status: string[]
	filter: string
}
export const get = async ({ page, limit, status, filter }: IGetAppointments, state: any) => {
	const { session, doctorCurrent } = state
	const { cpf, accessToken, isDoctor } = session
	const { cpf: doctorCpf } = doctorCurrent

	const statusUri = !filter ? status.map(item => `status=${item}`).join('&') : ''
	const orderBy = statusUri.includes('CREATED') ? 'ASC' : 'DESC'
	const filterUri = filter && `&patientName=${filter}`

	if (!accessToken || (!isDoctor && !doctorCpf)) {
		throw Error('Sem token')
	}

	const url = `/appointments?doctorCpf=${
		isDoctor ? cpf : doctorCpf
	}&page=${page}&limit=${limit}&side=${orderBy}&${statusUri}${filterUri}`
	try {
		const response = await api.get(url)
		return response
	} catch (err) {
		return err.response
	}
}

export const create = async (
	{
		email,
		name,
		date,
		duration,
		code,
		telephone,
		eligibilityId,
		isAppointmentReturn,
		transaction
	}: any,
	state: any
) => {
	const { session, doctorCurrent } = state
	try {
		const { cpf, isDoctor } = session
		const { cpf: doctorCpf } = doctorCurrent
		const response = await api.post('/appointments', {
			isAppointmentReturn,
			patientEmail: email,
			patientName: name,
			doctorCpf: isDoctor ? cpf : doctorCpf,
			doctorCnpj: code || (isDoctor ? cpf : doctorCpf),
			date,
			eligibilityId,
			duration,
			patientPhone: telephone.replace(/\D/g, ''),
			transaction
		})
		return response
	} catch (err) {
		return err.response
	}
}

export const deleteAppointment = async (id: string, state: any) => {
	const { session, doctorCurrent } = state
	try {
		const { isDoctor } = session
		const { cpf: doctorCpf } = doctorCurrent
		const uri = `/appointments/${!isDoctor ? 'secretarys/' : ''}${id}`
		const response = await api.delete(uri, {
			params: { doctorCpf }
		})
		return response
	} catch (err) {
		return err.response
	}
}

export const sendUrl = async (id: string, type: 'sms' | 'email') => {
	try {
		const response = await api.post(`/appointments/${id}/${type}`, {})
		return response
	} catch (err) {
		return err.response
	}
}

export const putProntmedId = async (
	consultationId: string,
	prontmedId: number,
	patientProntmedId: string
) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.put(endpoint.CONSULTATION.PUT_CONSULTATION_PRONTMED_ID, {
			appointmentId: consultationId,
			medicalEncounterId: prontmedId,
			patientId: patientProntmedId
		})
		return response
	} catch (err) {
		return err.response
	}
}

export const getPdfsConsultation = async (consultationId: string, docs: string[]) => {
	try {
		const types = `type=${docs.join('&type=')}`
		const endpoint = endpointsCreator(consultationId, types)
		const response = await api.get(endpoint.CONSULTATION.GET_PDF_CONSULTATION)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const downloadPdfConsultation = async (id: string, type: string) => {
	try {
		const endpoint = endpointsCreator(id, type)
		const response = await api.get(endpoint.CONSULTATION.DOWNLOAD_PDF)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const getPrescriptions = async (doctorCpf: string, patientCpf: string) => {
	try {
		const endpoint = endpointsCreator(doctorCpf, patientCpf)
		const response = await api.get(endpoint.CONSULTATION.GET_PRESCRIPTIONS)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const getMedicalRecords = async (patientId: string) => {
	try {
		const endpoint = endpointsCreator(patientId)
		const response = await api.get(endpoint.CONSULTATION.GET_MEDICAL_RECORDS)
		return response
	} catch (err) {
		console.log('error', err)
	}
}

export const postCreateClosedConsultation = async (
	consultationId: string,
	prontmedId: number,
	patientProntmedId: string
) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.CONSULTATION.POST_CREATE_CLOSED_CONSULTATION, {
			appointmentId: consultationId,
			medicalEncounterId: prontmedId,
			patientId: patientProntmedId
		})
		return response
	} catch (err) {
		return err.response
	}
}

export const postSendNotification = async (
	consultationId: string,
	docs: string[],
	typeNotification = 'email'
) => {
	try {
		const types = `type=${docs.join('&type=')}`
		const endpoint = endpointsCreator(consultationId, types)
		let response
		if (typeNotification === 'email')
			response = await api.post(endpoint.CONSULTATION.POST_SEND_PDF_EMAIL)
		else response = await api.post(endpoint.CONSULTATION.POST_SEND_PDF_LINK_SMS)
		return response
	} catch (err) {
		return err.response
	}
}

export const recordPrescriptionSold = async body => {
	const endpoint = endpointsCreator()

	const response = await api.put(endpoint.CONSULTATION.RECORD_CONTROLLED_SELL, body)
	return response
}

export const postCreateClosedConsultationBradesco = async (
	consultationId: string,
	prontmedId: number,
	patientProntmedId: string
) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.post(
			endpoint.CONSULTATION.POST_CREATE_CLOSED_CONSULTATION_BRADESCO,
			{
				appointmentId: consultationId,
				medicalEncounterId: prontmedId,
				patientId: patientProntmedId
			}
		)
		return response
	} catch (err) {
		return err.response
	}
}

export const getSupport = async (doctorCpf: string) => {
	try {
		const endpoint = endpointsCreator(doctorCpf)
		const response = await api.get(endpoint.SUPPORT.GET_SUPPORT)
		return response
	} catch (err) {
		console.log('error', err)
	}
}

export const deleteSupport = async (id: string) => {
	try {
		const endpoint = endpointsCreator(id)
		const response = await api.delete(endpoint.SUPPORT.DELETE)
		return response
	} catch (err) {
		return err.response
	}
}
