import api from './api'
import { endpointsCreator } from './endpoints'

interface IDoctorInfo {
	name: string
	email: string
	cpfCnpj: string
	cellPhone: string
}

export const getCertificatesValid = async (doctorCpf: string) => {
	try {
		const endpoint = endpointsCreator(doctorCpf)
		const response = await api.get(endpoint.VALID_CERTIFICATION.GET_CERTIFICATES)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const getQrCodeValid = async (doctorCpf: string) => {
	try {
		const endpoint = endpointsCreator(doctorCpf)
		const response = await api.get(endpoint.VALID_CERTIFICATION.GET_QRCODE)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const postTokenValid = async (code: string) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.VALID_CERTIFICATION.POST_TOKEN, {
			code
		})
		return response
	} catch (err) {
		console.log(err)
	}
}

export const putUpdateDoctorOptins = async (optins: string[], newOptin: string) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.put(endpoint.VALID_CERTIFICATION.PUT_NOT_PERTURB_AD_CERT_CLOUD, {
			optins: [...optins, newOptin]
		})
		return response
	} catch (err) {
		console.log(err)
	}
}

export const getPushValid = async (doctorCpf: string) => {
	try {
		const endpoint = endpointsCreator(doctorCpf)
		const response = await api.get(endpoint.VALID_CERTIFICATION.GET_PUSH_VALID)
		return response
	} catch (err) {
		console.log(err)
	}
}

export const postCheckPushValid = async (code: string, certifier?: string, doctorCpf?: string) => {
	try {
		const endpoint = endpointsCreator()
		const payload: any = {
			certificator: certifier
		}
		if (certifier === 'VALID') {
			payload.code = code
		} else if (certifier === 'SOLUTI') {
			payload.doctorCpf = doctorCpf
			payload.password = code
		}
		const response = await api.post(endpoint.VALID_CERTIFICATION.POST_CHECK_PUSH_VALID, {
			...payload
		})
		return response
	} catch (err) {
		console.log(err)
	}
}

export const postGenerateCertificateTicket = async (info: IDoctorInfo) => {
	try {
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.VALID_CERTIFICATION.POST_GENERATE_CERTIFICATION_TICKET, {
			...info
		})
		return response
	} catch (err) {
		console.log(err)
	}
}

export const postSignPdf = async (args: {
	consultationId: number
	token: string
	docs: string[]
	certifier: string
}) => {
	try {
		const { consultationId, token, docs, certifier } = args
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.VALID_CERTIFICATION.POST_SIGN, {
			encounterId: consultationId,
			token: `Bearer ${token}`,
			type: docs,
			certificator: certifier
		})
		return response
	} catch (err) {
		console.log(err)
	}
}

export const postSignPdfBradesco = async (args: {
	consultationId: number
	token: string
	docs: string[]
	certifier: string
}) => {
	try {
		const { consultationId, token, docs, certifier } = args
		const endpoint = endpointsCreator()
		const response = await api.post(endpoint.VALID_CERTIFICATION.POST_SIGN_BRADESCO, {
			encounterId: consultationId,
			token: `Bearer ${token}`,
			type: docs,
			certificator: certifier
		})
		return response
	} catch (err) {
		console.log(err)
	}
}
